import PropTypes  from 'prop-types';
import React      from 'react';
import classNames from 'classnames';

import * as S                  from './bm-free.module.scss';
// import { BmTopToggle }         from '../../../modals/subscription-modal/info-bm/bm-top-up';
// import { SubPaymentMethod }    from '../../../modals/subscription-modal/sub-payment-area';
import { ProductName }         from '../../../common/product/prod-name';
// import { TagLine }             from '../../../common/product/tag-line';
import { Expiry }              from '../../../common/product/expiry';
import * as Actions            from '../../../../context/ctx-actions';
import { useGlobalCtx }        from '../../../../context/ctx-hook';
import * as BillSelectors      from '../../../../context/selectors/bill-selectors';
import * as ProductSelectors   from '../../../../context/selectors/product-selectors';
import { MODALS }              from '../../../../helpers/constants/state-constants';
import * as UserSelectors      from '../../../../context/selectors/user-selectors';
import { getBillManagerType } from '../../../../context/selectors/bill-selectors';



const BmFree = ({isModal, qknIsActive, listPosition}) => {
  const {globalDispatch, state} = useGlobalCtx();
  const BM_TYPE      = getBillManagerType(state);
  const BM_MAX_ENT = BillSelectors.getMaxEntitlement(state);
  const BM_ENT_DATA = BillSelectors.getBmTierData(BM_MAX_ENT);


  const IS_PROMO_ENT = BillSelectors.getBMHasPromoEntitlement(state);
  const BM_ENT = IS_PROMO_ENT ? ProductSelectors.getSubscription(state, 'billmanager-promotion') : false;

  const PRODUCT =  IS_PROMO_ENT ?
    'billmanager-promotion':
    'billmanager';

  const PRODUCT_PAYMENT = UserSelectors.getProductsPaymentMethod(PRODUCT, state);
  const HAS_ACH         = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;

  const hangLengthClass = ( listPosition > 0 ?  (listPosition > 1 ? S.extraHanging : S.longHanging) : S.shortHanging);

  const handleChangePlan = React.useCallback(
    () => globalDispatch(Actions.setCtxNestedObj('modal', {
      type:       MODALS.CHANGE_PLAN,
      ctx:        {product: PRODUCT},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );


  const { tierName } = (IS_PROMO_ENT) ?
    BM_ENT :
    ProductSelectors.getSubscription(state, `quicken-${state.country}`);

  const IS_STANDARD = (IS_PROMO_ENT) ?
    BM_ENT.tierUriName === 'standard':
    BillSelectors.getBMHasEntitlement(state, ['BILLPAY_STANDARD']);

  return (
    <div className={`${isModal ? S.bmModalTile : S.bmTile} `}>
      {/* <div className={`${isModal ? S.bmModalTile : S.bmTile} ${IS_STANDARD ? 'standard' : 'extra'}`}> */}
      {/* <span className={classNames(`${S.plusSymbol} ${hangLengthClass} ${isModal ? S.noLine : ''}`)}>+</span> */}
      { IS_PROMO_ENT ?
        <ProductName sub={BM_ENT} product='bill manager' className={S.qknName}/> :
        <ProductName sub={BM_ENT_DATA} product='bill manager' className={S.qknName}/>
      }

      {/* <TagLine uriName={IS_STANDARD ? 'standard' : 'extra'} className={S.qknTagLine}/> */}

      {/* <div className={S.payCounts}> */}
      {/* <p>Check Pay - {BM_ENT_DATA.checkPay} per month</p> */}
      {/* <p>Quick Pay - {BM_ENT_DATA.quickPay} per month</p> */}
      {/* </div> */}

      {/* {!IS_PROMO_ENT && */}
      {/* <p className={S.benefit}>Included with Quicken {tierName}</p> */}
      {/* } */}
      
      {BM_TYPE == 'FREE' && !IS_PROMO_ENT &&
        <Expiry product={`quicken-${state.country}`} className={S.bmExpiryPromo} />
      }

      {BM_TYPE == 'PAID' &&
        <Expiry product={'billmanager'} className={S.bmExpiryPromo} />
      }

      {IS_PROMO_ENT &&
        <Expiry product={'billmanager-promotion'} className={S.bmExpiryPromo} />    
      }

      {/* {IS_PROMO_ENT &&
        <Expiry product={'billmanager-promotion'} className={S.bmExpiryPromo}/>
      } */}

      {/* Top-up toggle */}
      {/* <span className={S.dash} /> */}
      {/* <div className={S.bmTilePayment}>
        <BmTopToggle
          custClass="tileToggle"
        />

        {HAS_ACH && !IS_PROMO_ENT &&
          <SubPaymentMethod
            product={PRODUCT}
            custClass="tilePay"
          />
        }
      </div> */}

      {/* {!isModal && qknIsActive && (
        <>
          <span className={S.dash}/>
          <button
            type="button"
            onClick={handleChangePlan}
            className={S.bpCTA}
            data-testid="BTN_BM_BUY_MORE"
          >
            {IS_PROMO_ENT ?
              'Buy More Payments' : 
              'Upgrade Plan'
            }
          
          </button>
        </>
      )} */}
    </div>
  );
};

BmFree.displayName  = 'BmFree';
BmFree.propTypes    = {
  isModal: PropTypes.bool,
  qknIsActive: PropTypes.bool,
  listPosition: PropTypes.number
};
BmFree.defaultProps = {isModal: false, listPosition: 0};

export { BmFree };
