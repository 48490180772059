import * as UserSelectors   from './user-selectors';
import * as TimeHelpers     from '../../helpers/time-helpers';
import { checkRebrandName } from '../../helpers/pure-functions';



const getShortName = (prod) => {
  switch (prod) {
    case 'quicken-ca':
    case 'quicken-us':
      return 'Quicken';
    case 'acme':
      return 'Simplifi';
    case 'billmanager':
      return 'Bill Manager';
    case 'quicken-estate-manager':
      return 'Quicken LifeHub';
    case 'quicken-online-backup':
      return 'Online Backup';
    default:
      return '';
  }
};

const getProdList = country => [
  'acme',
  `quicken-${country}`,
  'billmanager',
  'quicken-estate-manager',
  'quicken-online-backup'
];

export const getAccountMessage = (globalState) => {

  return getProdList(globalState.country).reduce((acm, cur) => {
    const SUB = getSubscription(globalState, cur);
    if (!SUB){
      return acm;
    }
    const {active, autoRenew, expireOn} = SUB;

    const HAS_ACH    = UserSelectors.getUserHasAch(globalState);
    const HAS_CREDIT = UserSelectors.getUserHasCredit(globalState);
    const NAME       = getShortName(cur);
    const IS_BM      = cur === 'billmanager';

    if (active && !TimeHelpers.isWithin45Days(expireOn)) return acm;
    else if (!active) {
      return {
        ...acm,
        issue:    !acm.issue ? 'ACTIVITY' : 'COMBINED',
        messages: [
          !acm.issue
            ? `Your ${checkRebrandName(NAME)} subscription is no longer active`
            : 'Some of your subscriptions need your attention'
        ]
      };
    } else if (!autoRenew || (IS_BM && !HAS_ACH) || (!IS_BM && !HAS_CREDIT)) {
      return {
        ...acm,
        issue:    !acm.issue ? 'SUBSCRIPTION' : 'COMBINED',
        messages: [
          !acm.issue
            ? `Your ${checkRebrandName(NAME)} subscription is expiring in ${TimeHelpers.daysLeft(expireOn)} days.`
            : 'Some of your subscriptions need your attention'
        ]
      };
    } else {
      return acm;
    }

  }, {type: 'ERROR', messages: [], issue: null});
};

export const getAlertInfo = (globalState) => {
  const ALERTS = getProdList(globalState.country).reduce((acm, cur) => {
    const SUB = getSubscription(globalState, cur);

    if (!SUB) return acm;

    const {active, autoRenew, expireOn} = SUB;

    const HAS_PAYMENT = UserSelectors.getProductsPaymentMethod(cur, globalState);

    if (
      !active ||
      (active && autoRenew && HAS_PAYMENT) ||
      (!TimeHelpers.isWithin15Days(expireOn))
    ) return acm;

    return [
      ...acm, {
        product: cur,
        title:   `Your ${getShortName(cur)} subscription is expiring in ${TimeHelpers.daysLeft(SUB.expireOn)} days.`,
        issue:   !autoRenew ? 'AUTORENEW' : 'PAYMENT',
        message: !autoRenew
          ? 'In order to not lose your connected services, you need to resume your subscription.'
          : 'In order to not lose your connected services, add a payment method for renewal'
      }
    ];
  }, []);
  
  const simplifiAlerts = ALERTS.filter((alert) => alert.product == 'acme');

  if(ALERTS.length === 1) {
    return ALERTS[0];
  } else if (ALERTS.length > 1 && simplifiAlerts.length === 1) {
    return simplifiAlerts[0];
  } else if (ALERTS.length > 1) {
    return ALERTS[0];
  } else {
    return null;
  }
};

export const getProductName = (globalState, product) => {
  const {tierName = '', productLineName = ''} = getSubscription(globalState, product) || {};
  switch (product) {
    case 'acme':
      return 'Simplifi';
    case 'quicken-us':
    case 'quicken-ca':
      return `${tierName}`;
    case 'billmanager':
      return `${productLineName} ${tierName}`;
    case 'quicken-estate-manager':
      return `${productLineName}`;
    case 'quicken-online-backup':
      return `${productLineName}`;
    default:
      return '';
  }
};

export const getPriceBySku = (globalState, sku) => 
  globalState?.skus?.[sku]?.Price;

export const getQknPrice = (globalState, uriName) =>
  `$${globalState.tierPrices?.[uriName]}`;

export const uriNameToSku = (uriName) => {
  switch (uriName?.toLowerCase()) {
    case 'plus':
      return '170329';
    case 'volume50':
      return '170327';
    case 'volume100':
      return '170330';
    case 'volume200':
      return '170331';

    // bm promotional skus:
    case 'lite':
      return '170433';
    case 'basic':
      return '170434';
    case 'plusTrial':
      return '170435';
    
    case 'qob-basic':
      return '170515';

    default:
      return null;
  }
};

export const getSubscription = (globalState, product) => globalState?.subscriptions?.[product];
export const getCoTermSubs = (globalState) => Object.values(globalState?.subscriptions).filter(sub => sub.isCoTerminus);


export const getAcmeProduct = (globalState) => {
  const SUBS = Object.keys(globalState.subscriptions);
  if (SUBS.includes('acme')) {
    return 'acme';
  } else if (SUBS.includes('acme-apple')) {
    return 'acme-apple';
  } else if (SUBS.includes('acme-google')) {
    return 'acme-google';
  } else {
    return null;
  }

};

export const getProducts = globalState => {
  const HAS_QKN = !!getSubscription(globalState, `quicken-${globalState.country}`);
  const HAS_QOB = !!getSubscription(globalState, 'quicken-online-backup');
  const HAS_QEM = !!getSubscription(globalState, 'quicken-lifehub');
  const HAS_BM  = !!getSubscription(globalState, 'billmanager');

  return {
    quicken:      HAS_QKN ? `quicken-${globalState.country}` : null,
    simplifi:     getAcmeProduct(globalState),
    onlineBackup: HAS_QEM ? 'quicken-online-backup' : null,
    lifeHub:      HAS_QEM ? 'quicken-lifehub' : null,
    billmanager:  HAS_BM ? 'billmanager' : null
  };
};

export const getDiscountedDataBySku = (globalState, sku) =>{
  return {DisplayFullPrice : globalState?.skus?.[sku]?.DisplayFullPrice, DisplayDiscount : globalState?.skus?.[sku]?.DisplayDiscount};
};

export const mergeSubscription = (globalState, product, data) => ({
  ...globalState.subscriptions,
  [product]: {
    ...globalState.subscriptions[product],
    ...data
  }
});

export const getEffectiveMoment = (globalState, product) => {
  return getSubscription(globalState, product)?.['effectiveAt'] || null;
};

export const simplifiTierDisplayName = (tierName, nameType = false) => {
  // check for the old names and abort the rest of the function for backward compatibility since the api responses changed after we have already deployed
  // This function should be removed once the production tier names have been changed
  const oldNames = [
    'Simplifi - Basic',
    'Simplifi - Premier',
    'Simplifi - Home and Business',
  ];
  if (!oldNames.includes(tierName)) {
    return tierName;
  }
  const tierNameToFullDisplayName = {
    'Simplifi - Basic': 'Quicken Simplifi',
    'Simplifi - Premier': 'Quicken Premier',
    'Simplifi - Home and Business': 'Quicken Business & Personal',
  };

  const tierNameToDisplayName = {
    'Simplifi - Basic': 'Simplifi',
    'Simplifi - Premier': 'Premier',
    'Simplifi - Home and Business': 'Business & Personal',
  };

  if (nameType == 'fullname') {
    return tierNameToFullDisplayName[tierName] || 'Quicken Simplifi';
  } else {
    return tierNameToDisplayName[tierName] || 'Quicken Simplifi';
  }
};

export const getArCancelProductPoints = (tierUriName) => {
  const defaultPoints = [
    'Bank Downloads',
    'Free Phone Support',
    'Stock Price Updates',
    'Regular Updates, Fixes, and Improvements',
    'Account Sync'
  ];

  const simplifiQbpPoints = [
    'Managing business & personal finances, all from one app',
    'Automatically syncing financial transactions',
    'Creating & sending invoices',
    'Making tax prep easier',
    'Generating business reports',
    'Free phone & chat support',
  ];

  const qemPoints = [
    'Your Quicken LifeHub data',
    'Sharing info with family',
    'Organizing essential info',
    'Syncing with Quicken finance software',
    'Preparing for emergencies',
  ];

  const qobPoints = [
    'Automatic data backup',
    'Secure data storage',
    'Easy data restoration',
    'Peace of mind',
    'Quicken integration'
  ];

  const productPointMapping = {
    'billmanager': [],
    'quicken-ca': defaultPoints,
    'quicken-us': defaultPoints,
    'starter': defaultPoints,
    'deluxe': defaultPoints,
    'premier': defaultPoints,
    'hab-rpm': defaultPoints,
    'acme': defaultPoints,
    'acme-basic': defaultPoints,
    'acme-hab': simplifiQbpPoints,
    'acme-google': defaultPoints,
    'acme-apple': defaultPoints,
    'quicken-estate-manager': qemPoints,
    'quicken-online-backup': qobPoints,
    'qob-basic': qobPoints,
  };

  if(!productPointMapping[tierUriName]) {
    console.warn(`No cancellation points for ${tierUriName}, using defaults`);
  }
  return productPointMapping[tierUriName] || defaultPoints;
};

export const getArCancelModalTitle = (tierUriName, tierName) => {
  const defaultTitle = 'Did you know that...';
  const qobTitle = 'Do you really want to cancel Quicken Online Backup renewal?';
  const simplifiTitle = `Do you really want to cancel Quicken ${simplifiTierDisplayName(tierName, 'fullname')} renewal?`;

  const productTitleMapping = {
    'billmanager': defaultTitle,
    'quicken-ca': defaultTitle,
    'quicken-us': defaultTitle,
    'acme': simplifiTitle,
    'acme-basic': simplifiTitle,
    'acme-hab': simplifiTitle,
    'acme-google': defaultTitle,
    'acme-apple': defaultTitle,
    'quicken-estate-manager': defaultTitle,
    'quicken-online-backup': qobTitle,
  };

  if (!productTitleMapping[tierUriName]) {
    console.warn(`Cancel AR title no title for ${tierUriName}, using defaults`);
  }

  return productTitleMapping[tierUriName] || defaultTitle;
};