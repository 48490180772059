import React from 'react';
import PropTypes from 'prop-types';

import * as S from './coterm-tile.module.scss';

// Components
import { ProductName } from '../../../common/product/prod-name';
import { Price } from '../../../common/product/price';
import { Expiry } from '../../../common/product/expiry';
import { TagLine } from '../../../common/product/tag-line';
import { TileCoTermMiniSub } from './coterm-mini-sub-tile';

// Context Functions
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import * as UserSelectors from '../../../../context/selectors/user-selectors';
import { setCtxNestedObj } from '../../../../context/ctx-actions';
import { useGlobalCtx } from '../../../../context/ctx-hook';
import { getEStoreCartUrlAddProduct } from '../../../../helpers/route-helper';


import * as StateConstants from '../../../../helpers/constants/state-constants';

// Selector Functions
// import { getBillManagerType } from '../../../../context/selectors/bill-selectors';

const getSubSumPrice = (subList, state) => {
  const subPriceList = subList.map(sub => ProductSelectors.getPriceBySku(state, sub.renewalSku));  
  const totalSum = subPriceList.reduce((accumulator, price) => {
    const numericValue = parseFloat(price.replace('$', ''));
    return Math.round((accumulator + numericValue) * 100) / 100;
  }, 0);
  return totalSum;
};

const getAccountMsg = (SUBS, hasPayment) => {
  const ACTIVE_FLAGS = SUBS.map(sub => sub.active);
  const AR_FLAGS = SUBS.map(sub => sub.autoRenew);

  if (ACTIVE_FLAGS.every(value => value === false)) {
    return {
      title: 'Expired Subscription',
      message: 'This subscription is no longer active',
    };
  } else if (!hasPayment) {
    return {
      title: 'No Payment Method',
      message: 'In order to not lose your connected services, add a payment method for renewal'
    };
  } else if (AR_FLAGS.every(value => value === false)) {
    return {
      title: 'Renewal Canceled',
      message: 'Your subscription has been canceled but it is still valid until your expiration date. In order to avoid expiration, you must resume your subscription.'
    };
  } else {
    return false;
  }
};

const TileCoTerm = ({isModal = false}) => {
  const {globalDispatch, state} = useGlobalCtx();
  // Get all the coterms subs
  const SUBS = ProductSelectors.getCoTermSubs(state);
  const AR_ON_SUBS = SUBS.filter(sub => sub.autoRenew === true && sub.productLineUriName !== 'billmanager');

  // Filter out addons for our 'parent' sub list, probably need a better way to do this
  const ADDON_PRODUCTLINES = ['quicken-online-backup','billmanager'];
  const parentSubs = SUBS.filter(sub => !ADDON_PRODUCTLINES.includes(sub.productLineUriName));
  const addOnSubs = SUBS.filter(sub => ADDON_PRODUCTLINES.includes(sub.productLineUriName));
  const TOTAL_SUB_PRICE = '$' + getSubSumPrice(AR_ON_SUBS, state);
  const renewalFrequency = parentSubs[0].renewalFrequency;

  const ALL_SUBS_AR = SUBS.map(sub => sub.autoRenew);
  const ALL_SUBS_AR_OFF = SUBS.map(sub => sub.autoRenew).every(value => value === false);
  const ANY_SUB_ACTIVE = SUBS.filter(sub => sub.active == true).length >= 1;
  const ALL_SUBS_EXPIRED = SUBS.length === SUBS.filter(sub => sub.active == false).length;
  const ALL_EXPIRED_SKUS = SUBS.map(sub => {
    if(sub.active === false) {
      return sub.renewalSku;
    }
  });
  const SKU_PARAM = ALL_EXPIRED_SKUS.join(',');

  // const HAS_PAYMENT = true;
  const EXPIRY_SUB_TO_USE = ALL_SUBS_AR_OFF ? parentSubs[0] : AR_ON_SUBS[0];
  const HAS_PAYMENT = UserSelectors.getProductsPaymentMethod(EXPIRY_SUB_TO_USE, state);
  // console.log(HAS_PAYMENT);
  const MESSAGE = getAccountMsg(SUBS, HAS_PAYMENT);


  const manageSubscription = React.useCallback(
    async () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.SUBSCRIPTION,
      ctx:        {product: SUBS},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, SUBS]
  );
  
  // console.log('addOnSubs: ' + addOnSubs);
  // console.log('SKU_PARAM: ' + SKU_PARAM);
  // console.log('ALL_EXPIRED_SKUS: ' + ALL_EXPIRED_SKUS);
  // console.log('MESSAGE: ' + MESSAGE);
  // console.log(MESSAGE);
  // console.log('ALL_SUBS_AR: ' + ALL_SUBS_AR);
  // console.log('ALL_SUBS_AR_OFF: ' + ALL_SUBS_AR_OFF);
  // console.log('EXPIRY_SUB_TO_USE: ' + EXPIRY_SUB_TO_USE);
  // console.log(EXPIRY_SUB_TO_USE);
  // console.log('HAS_PAYMENT: ' + HAS_PAYMENT);

  return (
    <div className={isModal ? S.coTermModalWrapper : S.coTermWrapper} data-testid="SUB_COTERM">
      <div className={`${S.tile} coterm`}>
        <div className={S.subTileTop}>

          <div className={S.subTileLeft}>
            <div className={S.coTermTitleArea}>
              {parentSubs.map((sub, i) => {
                return (
                  <div className={S.coTermName} key={i}>
                    <div>
                      {['quicken-us', 'quicken-ca'].includes(sub.productLineUriName) &&
                        <ProductName sub={sub} product={sub.productName} className={S.acmeName} nameStyle='inline' customName=' ' />
                      }

                      {!['quicken-us', 'quicken-ca'].includes(sub.productLineUriName) &&
                        <ProductName sub={sub} product={sub.productName} className={S.acmeName} nameStyle='inline' />
                      }
                    </div>
                    {(i !== parentSubs.length - 1) &&
                      <span className={S.plusSign}>{' +'}</span>
                    }
                  </div>
                );
              })}
            </div>

            <TagLine uriName={EXPIRY_SUB_TO_USE.tierUriName} className={S.tagLine} />

            {!ALL_SUBS_AR_OFF &&
              <Price price={TOTAL_SUB_PRICE} renewalFrequency={renewalFrequency} className={`${S.price} ${parentSubs.length > 0 ? S.hasAddons : false}`} />
            }
          </div>
        
          <div className={S.subTileRight}>
            <Expiry sub={EXPIRY_SUB_TO_USE} product={EXPIRY_SUB_TO_USE.productLineUriName} className={S.productExpiry} />

            <div className={`${S.actionBtnWrapper} ${parentSubs.length > 0 ? S.hasAddons : false}`} >
              {!isModal && ANY_SUB_ACTIVE && HAS_PAYMENT && (
                <button
                  type="button"
                  data-testid="BTN_QKN_MANAGE_SUB"
                  onClick={manageSubscription}
                  className={S.ctaBtn}
                >Manage Subscription</button>
              )}

              {!isModal && !HAS_PAYMENT && (
                <button
                  type="button"
                  data-testid="BTN_QKN_MANAGE_SUB"
                  onClick={manageSubscription}
                  className={S.ctaBtn}
                >Add Billing</button>
              )}

              {ALL_SUBS_EXPIRED &&
                <a
                  type="button"
                  data-testid="BTN_QKN_BUY_NOW"
                  href={getEStoreCartUrlAddProduct(SKU_PARAM, state.country)}
                  className={S.ctaBtn}
                >Buy Now</a>
              }
            </div>
          </div>
        </div>

        {ALL_SUBS_AR_OFF && MESSAGE &&
          <div className={S.productAlert}>
            <p className={S.messageTitle}>{MESSAGE.title}</p>
            <p>{MESSAGE.message}</p>
          </div>
        }

        {parentSubs.length > 0 &&
          <div className={S.subTileBtm}>
            <span className={S.dash} />
            <div className={S.subTileMiniContain}>
              {parentSubs.map((sub, i) => {
                // <SubTileMini />
                return (
                  <div key={i}>
                    <TileCoTermMiniSub
                      sub={sub}
                      allSubsArOff={ALL_SUBS_AR_OFF}
                      allSubsExpired={ALL_SUBS_EXPIRED}
                    />
                  </div>
                );
              })}
            </div>


          </div>
        }
      </div>
    </div>
  );
};


TileCoTerm.displayName  = 'TileCoTerm';
TileCoTerm.propTypes    = {
  isModal: PropTypes.bool,
  subs:    PropTypes.array
};
TileCoTerm.defaultProps = {
  isModal: false,
  subs: []  
};

export { TileCoTerm };